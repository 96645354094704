import React, { useEffect } from "react";
import serviceImage from "../Assets/images/Frame 427321151 (1).png";
import employee from "../Assets/images/employee.webp";
import appointment from "../Assets/images/appointment.webp";
import inventory from "../Assets/images/inventory.webp";
import laser from "../Assets/images/laser.webp";
import analytics from "../Assets/images/analytics.webp";
import campaign from "../Assets/images/campaign.webp";
import invoice from "../Assets/images/Invoice.png";
import Expenditure from "../Assets/images/Expenditure.png";
import Reports from "../Assets/images/Discounts.png";
import Products from "../Assets/images/Products.png";
import Membership from "../Assets/images/Membership.png";
import Clients from "../Assets/images/clients.png";

const Services = () => {
  useEffect(() => {
    document.title = "IBER | Services";
  }, []);

  const cards = [
    {
      imageUrl: employee,
      name: "Employee Management",
      description: "Manage your team with ease. Assign personalized permissions, track performance, and monitor service history all in one place. Keep your staff motivated and informed with a user-friendly interface."
    },
    {
      imageUrl: appointment,
      name: "Appointment",
      description: "Effortless booking, anytime. Your clients can easily schedule appointments according to your availability, and you can manage them seamlessly. Customizable notifications and reminders ensure no one ever misses a session."
    },
    {
      imageUrl: inventory,
      name: "Inventory",
      description: "Simplify budgeting, restocking, and tracking of inventory. Stay ahead of demand with real-time updates and forecasts, so you're always prepared. Easily track product levels and generate low-stock alerts."
    },
    {
      imageUrl: laser,
      name: "Ledger",
      description: "Financial clarity at your fingertips. Track every penny with precision, easily reconcile accounts, and manage your income and expenses in one secure space. Take control of your finances with detailed reports and insights."
    },
    {
      imageUrl: analytics,
      name: "Analytics",
      description: "Elevate your business with our powerful analytics and reporting tools. Visualize key metrics, identify trends, and generate custom reports that help you make data-driven decisions to scale and grow your business effectively."
    },
    {
      imageUrl: campaign,
      name: "Campaign",
      description: "Craft campaigns that captivate and keep clients coming back. Design targeted promotions, track their success, and use in-depth analytics to optimize your marketing strategies. Stay ahead of the competition with personalized client outreach."
    },
    {
      imageUrl: invoice,
      name: "Invoice",
      description: "Streamline your billing process with our intuitive invoice software. Create, send, and manage invoices in a few clicks. Automated payment reminders and easy tracking of overdue balances help you get paid faster."
    },
    {
      imageUrl: Expenditure,
      name: "Expenditure",
      description: "Spend smart, save more. Keep your financial peace of mind with advanced expenditure tracking and budgeting tools. Stay on top of expenses, monitor cash flow, and make informed decisions to optimize your business's financial health."
    },
    {
      imageUrl: invoice,
      name: "Reports",
      description: "Get the insights you need to make informed business decisions. Generate custom reports on sales, performance, and client activity to uncover opportunities and optimize your strategy. Data-driven decisions have never been easier."
    },
    {
      imageUrl: Products,
      name: "Products",
      description: "From shelf to sale, manage your products with ease. Track product details, inventory, and pricing, ensuring your business runs smoothly. Offer seamless product ordering, restocking, and inventory management for optimal efficiency."
    },
    {
      imageUrl: Membership,
      name: "Membership",
      description: "Where loyalty meets rewards. Encourage repeat business by offering tailored membership programs that reward clients for their loyalty. Exclusive benefits, discounts, and early access to promotions keep members engaged and satisfied."
    },
    {
      imageUrl: Clients,
      name: "Clients",
      description: "Build lasting client relationships with special offers and rewards. Personalize your interactions, track client preferences, and create custom incentives to keep them coming back. Deliver exceptional service and build a loyal client base."
    },
  ];

  return (
    <React.Fragment>
      <div className="relative">
        <img
          className="h-auto w-full mb-10  object-cover "
          src={serviceImage}
          alt="salon-pic"
        />
        <div
          className="absolute top-0 left-0 right-0 bottom-0 flex items-center justify-center"
          style={{ backgroundColor: "rgba(0, 0, 0, 0.5)" }}
        >
          <h1 className="text-white text-5xl font-bold pulse-HeaderImg">
            Services
          </h1>
        </div>
      </div>

      <div className="font-bold tracking widest text-center flex-col flex gap-2">
        <div className="text-4xl">Spa & Salon Management Software  </div>
        <div className="text-xl" >We are Dealing With All This Features</div>
      </div>
      <div className=" mx-10 my-10">
        <div className="grid md:grid-cols-3 gap-4">
          {cards.map((card, index) => (
            <div
              key={index}
              className="border border-gray-300 hover:border-primaryColor p-4 rounded-lg transition ease-in-out hover:duration-300 hover:scale-105 transform"
              style={{ animation: "fadeInUp 0.5s ease-in-out" }}
            >
              <div className="flex flex-col md:flex-row md:flex-wrap items-center md:items-start md:space-x-4">
                <img
                  src={card.imageUrl}
                  alt={card.name}
                  className="w-24 h-24 md:w-32 md:h-32 object-cover rounded-md"
                  style={{ animation: 'spin-and-pause 4s linear infinite' }}
                />

                <div className="mt-2 md:mt-0 text-center md:text-left flex-1">
                  <h3 className="text-primaryTextColor font-bold text-xl my-2 tracking-wide">
                    {card.name}
                  </h3>

                  <p className="text-[#A9A8C0] text-base tracking-wide">
                    {card.description}
                  </p>
                </div>
              </div>
            </div>
          ))}
        </div>
      </div>
    </React.Fragment>
  );
};

export default Services;
